<template>
    <div>
        <div class="container">
            <h1>{{$t('lastOrders.heading')}}</h1>
            <p>{{$t('lastOrders.subtitle')}}</p>
        </div>
        <div class="container-fluid">
            <last-orders-data-table
                :dataset="(aggregateBy === null) ? lastOrders : ((aggregateBy === 'stock') ? lastOrdersByStock : lastOrdersByAgent)"
                :loading="lastOrdersLoading"
                :dataset-type="aggregateBy"
            >
                <loader-overlay
                    :loading="filtersLoading"
                    class="mb-5"
                >
                    <last-orders-filter-form
                        :filter-options="filterOptions"
                        :client="client"
                        :agent="agent"
                        :stock="stock"
                        :from="from"
                        :to="to"
                        @reset="onReset"
                        @submit="onFilter"
                        @filters="onLoadFilters"
                    />

                    <div class="mt-4">
                        <p class="mb-1">
                            <strong>Agregace pozic dle:</strong>
                        </p>
                        <b-btn
                            size="sm"
                            variant="light"
                            @click.prevent="aggregateBy = null"
                            class="mr-2 mb-2"
                        >
                            Neagregovat
                        </b-btn>
                        <b-btn
                            size="sm"
                            :variant="(aggregateBy === 'stock') ? 'warning' : 'primary'"
                            @click.prevent="aggregateBy = 'stock'"
                            class="mr-2 mb-2"
                        >
                            Cenného papíru
                        </b-btn>
                        <b-btn
                            size="sm"
                            :variant="(aggregateBy === 'agent') ? 'warning' : 'primary'"
                            @click.prevent="aggregateBy = 'agent'"
                            class="mr-2 mb-2"
                        >
                            Agenta + cenného papíru
                        </b-btn>
                    </div>
                </loader-overlay>
            </last-orders-data-table>
        </div>
    </div>
</template>

<script>
import LoaderOverlay from '../../../../../../Components/LoaderOverlay.vue'
import LastOrdersDataTable from './Components/LastOrdersDataTable.vue'
import LastOrdersFilterForm from './Components/LastOrdersFilterForm.vue'

export default {
    name: 'LastOrders',
    components: {
        LastOrdersDataTable,
        LastOrdersFilterForm,
        LoaderOverlay
    },
    props: {
        client: {
            type: String,
            required: false,
            default: null
        },
        agent: {
            type: String,
            required: false,
            default: null
        },
        stock: {
            type: String,
            required: false,
            default: null
        },
        from: {
            type: String,
            required: false,
            default: null
        },
        to: {
            type: String,
            required: false,
            default: null
        }
    },
    data () {
        return {
            aggregateBy: null,
            filterOptions: {
                clients: [],
                stocks: [],
                agents: []
            },
            lastOrdersLoading: true,
            lastOrders: [],
            lastOrdersByStock: [],
            lastOrdersByAgent: [],
            nextTickTrigger: false,
            filtersLoading: true,
            tempFilters: {
                filterClient: this.$route.query.client || undefined,
                filterISIN: this.$route.query.stock || undefined,
                filterAgent: this.$route.query.agent || undefined,
                limitFrom: this.$route.query.from || undefined,
                limitTo: this.$route.query.to || undefined
            }
        }
    },
    computed: {
        emptyFilter () {
            return { value: null, text: this.$t('lastOrders.doNotApplyFilter') }
        }
    },
    watch: {
        aggregateBy (newValue) {
            this.$nextTick(() => {
                if (newValue === 'stock') {
                    this.computeOrdersByStock()
                } else if (newValue === 'agent') {
                    this.computeOrdersByAgent()
                }
            })
        },
        $route () {
            this.$nextTick(() => {
                this.loadLastOrders()
                this.tempFilters = {
                    filterClient: this.$route.query.client || undefined,
                    filterISIN: this.$route.query.stock || undefined,
                    filterAgent: this.$route.query.agent || undefined,
                    limitFrom: this.$route.query.from || undefined,
                    limitTo: this.$route.query.to || undefined
                }
                this.loadLastOrdersFilters()
            })
        }
    },
    mounted () {
        this.loadLastOrders()
        this.loadLastOrdersFilters()
    },
    methods: {
        computeOrdersByStock () {
            this.lastOrdersLoading = true
            this.lastOrdersByStock = []
            this.lastOrdersByStock = [...new Set(this.lastOrders
                .map(item => (
                    JSON.stringify({
                        currencySymbol: item.currencySymbol,
                        ISIN: item.ISIN,
                        stockName: item.stockName
                    })
                )))
            ].map(JSON.parse).map(item => {
                const stockRows = this.lastOrders.filter(entry => `${item.ISIN}${item.stockName}${item.currencySymbol}` === `${entry.ISIN}${entry.stockName}${entry.currencySymbol}`)
                return {
                    ...item,
                    amount: stockRows.reduce((sum, entry) => (sum += parseInt(entry.amount) || 0), 0),
                    volume: stockRows.reduce((sum, entry) => (sum += parseInt(entry.volume) || 0), 0),
                    numberOfOrders: stockRows.length,
                    numberOfClients: [...new Set(stockRows.map(item => item.capitolId))].length,
                    numberOfAgents: [...new Set(stockRows.filter(item => item.externalBrokerAgentCapitolId).map(item => item.externalBrokerAgentCapitolId))].length
                }
            })
            this.$nextTick(() => {
                this.lastOrdersLoading = false
            })
        },
        computeOrdersByAgent () {
            this.lastOrdersLoading = true
            this.lastOrdersByAgent = []
            this.lastOrdersByAgent = [...new Set(this.lastOrders
                .map(item => (
                    JSON.stringify({
                        externalBrokerAgentName: item.externalBrokerAgentName,
                        externalBrokerAgentCapitolId: item.externalBrokerAgentCapitolId,
                        ISIN: item.ISIN,
                        stockName: item.stockName,
                        currencySymbol: item.currencySymbol
                    })
                )))
            ].map(JSON.parse).map(item => {
                const stockRows = this.lastOrders.filter(entry => `${item.externalBrokerAgentCapitolId}` === `${entry.externalBrokerAgentCapitolId}`)
                    .filter(entry => `${item.ISIN}${item.stockName}${item.currencySymbol}` === `${entry.ISIN}${entry.stockName}${entry.currencySymbol}`)
                return {
                    ...item,
                    amount: stockRows.reduce((sum, entry) => (sum += parseInt(entry.amount) || 0), 0),
                    volume: stockRows.reduce((sum, entry) => (sum += parseInt(entry.volume) || 0), 0),
                    numberOfOrders: stockRows.length,
                    numberOfClients: [...new Set(stockRows.map(item => item.capitolId))].length
                }
            })
            this.$nextTick(() => {
                this.lastOrdersLoading = false
            })
        },
        onFilter (filterOptions) {
            this.$router.push({
                name: 'ClientsLastOrders',
                query: {
                    client: filterOptions.filterClient || undefined,
                    agent: filterOptions.filterAgent || undefined,
                    stock: filterOptions.filterISIN || undefined,
                    from: filterOptions.limitFrom || undefined,
                    to: filterOptions.limitTo || undefined
                }
            }).catch(() => {})
        },
        onReset () {
            this.$router.push({ name: 'ClientsLastOrders' }).catch(() => {})
        },
        onLoadFilters (tempFilters) {
            this.tempFilters = tempFilters
            this.$nextTick(() => {
                this.loadLastOrdersFilters()
            })
        },
        loadLastOrders () {
            this.nextTickTrigger = false
            this.lastOrdersLoading = true
            this.lastOrders = []
            this.$api.capitol.getBrokerLastOrders(
                this.$user.izdocsCompanyId,
                {
                    filterClient: this.$route.query.client || undefined,
                    filterISIN: this.$route.query.stock || undefined,
                    filterAgent: this.$route.query.agent || undefined,
                    limitFrom: this.$route.query.from || undefined,
                    limitTo: this.$route.query.to || undefined
                }
            ).then((response) => {
                this.lastOrders = [...response.data]
                this.$nextTick(() => {
                    if (this.aggregateBy === 'stock') {
                        this.computeOrdersByStock()
                    } else if (this.aggregateBy === 'agent') {
                        this.computeOrdersByAgent()
                    }
                })
            }).catch((error) => {
                console.error(error)
                this.$notify.error(this.$t('lastOrders.lastOrdersLoadError'))
            }).finally(() => {
                this.$nextTick(() => {
                    this.lastOrdersLoading = false
                })
            })
        },
        loadLastOrdersFilters () {
            this.filtersLoading = true
            this.$api.capitol.getBrokerLastOrdersFilters(
                this.$user.izdocsCompanyId,
                this.tempFilters
            ).then((response) => {
                this.filterOptions.clients = [this.emptyFilter, ...response.data.clients]
                this.filterOptions.stocks = [this.emptyFilter, ...response.data.stocks]
                this.filterOptions.agents = [this.emptyFilter, ...response.data.agents]
            }).catch((error) => {
                console.error(error)
            }).finally(() => {
                this.$nextTick(() => {
                    this.filtersLoading = false
                })
            })
        }
    }
}
</script>

<i18n>
{
    "cz": {
        "lastOrders": {
            "heading": "Seznam posledních pokynů klientů zprostředkovatele",
            "subtitle": "Seznam všech evidovaných posledních pokynů klientů zprostředkovatele.",
            "lastOrdersLoadError": "Nepodařilo se načíst seznam pokynů.",
            "doNotApplyFilter": "Neaplikovat filtr"
        }
    },
    "en": {
    }
}
</i18n>
