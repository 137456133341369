<template>
    <div class="p-4 bg-white shadow rounded-sm">
        <slot></slot>
        <data-table
            class="table-sm-font"
            :auto-update="false"
            :header="header"
            :loading="loading"
            :data="dataset"
            :buttons="buttons"
            :lang="currentLang"
            :paging="true"
            :responsive="'lg'"
            :actions="false"
            :exportable="true"
            @export="onExport"
        />
    </div>
</template>

<script>
import XLSX from 'xlsx'
import download from 'downloadjs'

const numberFormatter = new Intl.NumberFormat('cs-CZ')
const formatCurrency = (value) => {
    if (typeof value === 'number') {
        return numberFormatter.format(Math.round(value * 100) / 100)
    }
    return 0
}

export default {
    name: 'LastOrdersDataTable',
    props: {
        dataset: {
            type: Array,
            required: false,
            default: () => []
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        },
        datasetType: {
            type: String,
            required: false,
            default: null
        }
    },
    computed: {
        currentLang () {
            return (this.$store.state.lang === 'cz') ? 'cs_CZ' : 'en_US'
        },
        header () {
            return [
                ...((this.datasetType === null) ? [{
                    text: 'Capitol ID',
                    data: 'capitolId',
                    sortable: true,
                    filterable: true,
                    format: (value) => {
                        return value || this.$t('lastOrdersDataTable.noCapitolId')
                    }
                },
                {
                    text: 'Jméno klienta',
                    data: 'name',
                    sortable: true,
                    filterable: true
                }] : []),
                ...((this.datasetType === 'agent' || this.dataset === null) ? [{
                    text: 'Agent',
                    data: 'externalBrokerAgentName',
                    sortable: true,
                    filterable: true
                }] : []),
                {
                    text: 'ISIN',
                    data: 'ISIN',
                    sortable: true,
                    filterable: true
                },
                {
                    text: 'Název CP',
                    data: 'stockName',
                    sortable: true,
                    filterable: true
                },
                {
                    text: 'Objem v měně',
                    data: 'volume',
                    sortable: true,
                    filterable: true,
                    format: formatCurrency,
                    sortFn (a, b) {
                        return ((a === b) ? 0 : ((a > b) ? 1 : -1))
                    }
                },
                {
                    text: 'Měna',
                    data: 'currencySymbol',
                    sortable: true,
                    filterable: true
                },
                ...((this.datasetType === 'stock' || this.datasetType === 'agent') ? [{
                    text: 'Počet klientů',
                    data: 'numberOfClients',
                    sortable: true,
                    filterable: true
                },
                {
                    text: 'Počet pozic',
                    data: 'numberOfOrders',
                    sortable: true,
                    filterable: true
                }] : []),
                ...((this.datasetType === 'stock') ? [{
                    text: 'Počet agentů',
                    data: 'numberOfAgents',
                    sortable: true,
                    filterable: true
                }] : []),
                ...((this.datasetType === null) ? [{
                    text: 'Předpokládaný objem',
                    data: 'expectedVolume',
                    sortable: true,
                    filterable: true,
                    format: formatCurrency
                },
                {
                    text: 'Směr',
                    data: 'direction',
                    sortable: true,
                    filterable: true,
                    format: (value) => {
                        return (value) ? value === 'buy' ? 'Nákup' : 'Prodej' : value
                    }
                },
                {
                    text: 'Datum realizace',
                    data: 'realizationDate',
                    sortable: true,
                    filterable: true,
                    format: (value) => {
                        return (value) ? new Date(value).toLocaleDateString() : value
                    },
                    sortFn (a, b) {
                        a = (new Date(a)).valueOf()
                        b = (new Date(b)).valueOf()
                        return ((a === b) ? 0 : ((a > b) ? 1 : -1))
                    }
                },
                {
                    text: 'Datum pokynu',
                    data: 'lastOrderDate',
                    sortable: true,
                    filterable: true,
                    format: (value) => {
                        return (value) ? new Date(value).toLocaleString() : value
                    },
                    sortFn (a, b) {
                        a = (new Date(a)).valueOf()
                        b = (new Date(b)).valueOf()
                        return ((a === b) ? 0 : ((a > b) ? 1 : -1))
                    }
                },
                {
                    text: 'Datum první smlouvy',
                    data: 'firstContractDate',
                    sortable: true,
                    filterable: true,
                    format: (value) => {
                        return (value) ? new Date(value).toLocaleString() : value
                    },
                    sortFn (a, b) {
                        a = (new Date(a)).valueOf()
                        b = (new Date(b)).valueOf()
                        return ((a === b) ? 0 : ((a > b) ? 1 : -1))
                    }
                }] : [])
            ]
        },
        buttons () {
            return []
        }
    },
    methods: {
        onExport (data) {
            const wk = XLSX.utils.book_new()
            const [jsonHeader, ...rows] = data
            const formatAsNumber = (value) => {
                // eslint-disable-next-line no-useless-escape
                return Number(`${value}`.replace(/[^0-9,\.]/g, '').replace(',', '.') ?? 0)
            }
            const sheet = XLSX.utils.json_to_sheet([jsonHeader, ...rows.map(row => {
                if (row.volume !== undefined) {
                    row.volume = formatAsNumber(row.volume)
                }
                if (row.numberOfClients !== undefined) {
                    row.numberOfClients = formatAsNumber(row.numberOfClients)
                }
                if (row.numberOfOrders !== undefined) {
                    row.numberOfOrders = formatAsNumber(row.numberOfOrders)
                }
                if (row.numberOfAgents !== undefined) {
                    row.numberOfAgents = formatAsNumber(row.numberOfAgents)
                }
                return row
            })], { skipHeader: true })
            XLSX.utils.book_append_sheet(wk, sheet, 'export')
            const output = XLSX.write(wk, { bookType: 'xlsx', bookSST: false, type: 'array' })
            download(
                new Blob([output], { type: 'application/octet-stream' }),
                'posledni-pokyny.xlsx',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            )
        }
    }
}
</script>

<i18n>
{
    "cz": {
        "lastOrdersDataTable": {
            "noCapitolId": "Neevidováno",
            "total": "Celkem: "
        }
    },
    "en": {
        "noCapitolId": "Not in evidence",
        "total": "Total: "
    }
}
</i18n>
